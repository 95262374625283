import en from './en/translation.json'
import ch from './ch/translation.json'


const messages = {
  en,
  ch

}

export default messages;